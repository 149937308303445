import React, { useEffect } from "react";
import { useState, useMemo } from "react";
import { InAppTemplate } from "../../shared/templates/portal";
import Modal from "react-bootstrap/Modal";
import * as Yup from "yup";
import Button from "react-bootstrap/Button";
import { Form, Formik, FormikProps } from "formik";

import InputField from "../../shared/components/InputField";
import Col from "react-bootstrap/Col";
import DropDown from "../../shared/components/dropdown";
import Row from "react-bootstrap/Row";
import "./index.scss";
import { Container } from "react-bootstrap";

import {
  getStudentProfile,
  generateSchoolFeeInvoice
} from "../../redux/action/admission";
import { adminInAppActions } from "../../redux/action/portal";
import { handleRequestErrors } from "../../shared/utils";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { DotLoader } from "react-spinners";
import { history } from "../../shared/_helpers/history";
import { AlertMsg } from "../../shared/components/alert-msg";

interface InvoiceModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;

}

export interface SchoolFeeInvoice {
  fullName?: string;
  matricNumber: string;
  level: string;
  session: string;
}

const CreateStudentInvoiceModal: React.FC<InvoiceModalProps> = ({
  show,
  setShow,
}) => {
  const initialValues = {
    matricNumber: "",
  };

  const adminCurrentSession = useSelector(
    (state: any) => state.allUGradPortalReducers.adminGetCurrentSessionReducer
  );
  const dispatch = useDispatch();
  const fetchCurrentSession = () => {
    dispatch(adminInAppActions.AdminGetCurrentSession(""));
  };

  useEffect(() => {
    fetchCurrentSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const schoolSession =
    adminCurrentSession?.request_data?.response?.data?.items ?? [];
  const session = schoolSession.find(
    (item: any) => item?.type === "SCHOOL"
  )?.session;

  let checkValidationSchema = Yup.object().shape({
    matricNumber: Yup.string().required("Required"),
    session: Yup.string().required("Required"),
    level: Yup.string().required("Required"),

  });

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false)
        history.push("/app/portal-settings");
      }}
      contentClassName="additional-payment-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Generate School Fees Invoice
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="student-modal-form">
        <Formik
          initialValues={initialValues}
          validationSchema={checkValidationSchema}
          onSubmit={async (values) => { }}
        >
          {(formikProps: FormikProps<any>) => (
            <SchoolFeeInvoiceForm
              formikProps={formikProps}
              setShow={setShow}

              session={session}
            />
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

const SchoolFeeInvoiceForm: React.FC<{
  formikProps: FormikProps<any>;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;

  session: string;
}> = ({ formikProps, setShow, session }) => {
  const initialStudentValue = {
    fullName: "",
    studentId: "",
    matricNumber: "",
    level: "",
    session: ""

  };
  const { values, touched, errors, handleChange, setFieldValue, setFieldTouched } = formikProps;
  const matricNumber = values.matricNumber;
  const [student, setStudent] = useState<SchoolFeeInvoice>({
    ...initialStudentValue,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState("");
  const [downloadUrl, setDownloadUrl] = useState<string>("");
  const handleGetStudentProfile = async (matricNo: string) => {
    setStudent(initialStudentValue);
    setLoading(true);
    setError("");
    await getStudentProfile(matricNo)
      .then((data) => {
        setLoading(false);
        if (data?.data?.studentUniqueId) {
          const { studentUniqueId: studentId, fullName } = data?.data;
          setStudent((prevState) => ({
            ...prevState,
            studentId,
            fullName,
          }));
        }
      })
      .catch((err: any) => {
        setLoading(false);
        handleRequestErrors(err);
        if (err?.response?.status === 404) {
          setError(err?.response?.data);
          setStudent(initialStudentValue);
        }
      });
  };
  useEffect(() => {
    setStudent({
      ...student,
      matricNumber: values?.matricNumber,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);
  useEffect(() => {
    let searchDebounce: any;
    if (matricNumber.length >= 14) {
      searchDebounce = setTimeout(() => {
        handleGetStudentProfile(matricNumber);
      }, 500);
    }
    return () => clearTimeout(searchDebounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matricNumber]);

  const DownloadLink = ({ url}:any) => (
    <a href={url} className="download_invoice" target="_blank">Download Invoice
    </a>
  );
  const handleSubmit = async () => {
    if (!student?.fullName || !values?.level || !values?.session) {
      return setError("Matric number, Level and Session required");
    }
    let payload ={
      matricNumber: values?.matricNumber,
      level: values?.level,
      session:values?.session
    }
    console.log("student", payload)
    // return;
    setSubmitting(true);

    await generateSchoolFeeInvoice(payload)
      .then((res:any) => {
        setSubmitting(false);
        toast.success("School Fee invoice generated successfully");
        if(res?.data?.downloadUrl){
          setDownloadUrl(res?.data?.downloadUrl)
          
        }
        // setShow(false);
      })
      .catch((err: any) => {
        setSubmitting(false);
        handleRequestErrors(err);
        toast.error("An error occured, please try again.");
      });
  };

  return (
    <Form>
      {loading ? (
        <p className="student-name">Searching student...</p>
      ) : !loading && student?.fullName && matricNumber?.length ? (
        <p className="student-name">Name: {student?.fullName}</p>
      ) : null}
      {error?.length && !loading ? <p className="error">{error}</p> : null}
      <InputField
        field="matricNumber"
        label="Matric Number"
        name="matricNumber"
        value={values.matricNumber}
        touched={touched}
        errors={errors}
        handleChange={handleChange}
      />
      <DropDown
        width="100%"
        name="session"
        field="session"
        label="Session"
        setFieldValue={setFieldValue}
        options={[
          {
            value: session,
            label: session,
          },
        ]}
        value={{
          value: session,
          label: session,
        }}
        touched={touched}
        errors={errors}
      />
      <DropDown
        width="100%"
        label="Level"
        touched={touched}
        errors={errors}
        value={{
          value: values.level,
          label: values.level,
        }}
        options={[
          { value: "100", label: "100" },
          { value: "200", label: "200" },
          { value: "300", label: "300" },
          { value: "400", label: "400" },
          { value: "500", label: "500" },
          { value: "600", label: "600" },
        ]}
        field="level"
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
      />
      <div className="button-container">
        <Button className="submit-btn" onClick={handleSubmit}>
          {submitting ? "Submitting..." : "Submit"}
          {submitting ? (
            <DotLoader
              color="white"
              loading={submitting}
              size={30}
              aria-label="Submitting"
            />
          ) : null}
        </Button>
        
      </div>
      {downloadUrl && <DownloadLink url={downloadUrl}/>}
    </Form>
  );
};

const CreateStudentInvoiceWrap = () => {
  const [show, setShow] = useState<boolean>(true);
  return (
    <div className="modal-container">
      <CreateStudentInvoiceModal show={show} setShow={setShow} />
    </div>
  );
};

const GenerateInvoiceWrap = () => {
  return (
    <InAppTemplate
      pageTitle={`GENERATE SCHOOL FEES INVOICE`}
      childComponent={<CreateStudentInvoiceWrap />}
    />
  );
};

export default GenerateInvoiceWrap;
