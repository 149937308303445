import { useEffect, useState, useCallback } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { Form, Formik, FormikProps } from "formik";
import Button from "react-bootstrap/Button";
import * as Yup from "yup";
import { DotLoader } from "react-spinners";
import { toast } from "react-toastify";
import { levelOptions } from "../../../../shared/_helpers/constants";
import {
  fetchHostelData,
  hostelDownloadReport,
  hostelSearchReport,
} from "../../../../redux/action/admission";
import DropDown from "../../../../shared/components/dropdown";
import ReportTable from "./ReportTable";
import DownloadModal from "./DownloadModal";
import "./../index.scss";
import { LoadingItem } from "../../../../shared/components/loading";
import ReactPaginate from "react-paginate";

interface FormValues {
  level: string;
  session: string;
}

interface ReportModalProps {
  showFilter: boolean;
  setShowFilter: (show: boolean) => void;
  sessionOptions: { value: string; label: string }[];
  userData: { currentSession: { session: string } };
}

interface HostelDataItem {
  [key: string]: any;
}

const ReportModal: React.FC<ReportModalProps> = ({
  showFilter,
  setShowFilter,
  sessionOptions,
  userData,
}) => {
  const [loading, setLoading] = useState(false);
  const [hostelData, setHostelData] = useState<HostelDataItem[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [show, setShow] = useState(false);
  const [levelFilter, setLevelFilter] = useState<string>("All");
  const [fileType, setFileType] = useState<string>("CSV");
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [showLoading, setShowLoading] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState<string>("");
  const sessionFilter = userData?.currentSession?.session || "";

  const itemsPerPage = 20;

  const pageCount = Math.ceil(totalCount / 20);
  const lastPageCount = (currentPage - 1) * itemsPerPage + hostelData.length;

  const handlePageClick = async (event: any) => {
    const newPage = event.selected + 1;
    setCurrentPage(newPage);
    await fetchHostelReport(levelFilter, sessionFilter, searchTerm, newPage);
  };

  const initialValues: FormValues = {
    level: "100",
    session: sessionFilter,
  };

  const validationSchema = Yup.object({
    session: Yup.string().required("Session is required"),
    level: Yup.string().required("Level is required"),
  });

  const fetchHostelReport = useCallback(
    async (
      level: string,
      session: string,
      searchTerm?: string,
      page: number = currentPage
    ) => {
      setLoading(true);
      try {
        let response;
        if (searchTerm) {
          response = await hostelSearchReport(session, page, searchTerm);
        } else {
          response = await fetchHostelData(level, page, session);
        }

        if (response?.status === 200) {
          if (response.data?.items?.length > 0) {
            setHostelData(response.data.items);
            setTotalCount(response.data.totalCount);
            setShow(true);
            setShowFilter(false);
          } else {
            toast.error("No record found.");
            setShow(false);
            setSearchTerm("");
          }
        } else {
          toast.error("An error occurred while fetching the report.");
        }
      } catch (error) {
        toast.error("An error occurred while fetching the report.");
      } finally {
        setLoading(false);
      }
    },
    [setShowFilter, currentPage]
  );

  const handleFormSubmit = async (values: FormValues) => {
    const { level, session } = values;
    setLevelFilter(level);
    await fetchHostelReport(level, session, searchTerm);
  };

  const fetchReportDownload = async () => {
    setShowLoading(true);
    try {
      const response = await hostelDownloadReport(
        levelFilter,
        sessionFilter,
        fileType
      );
      if (response?.status === 200) {
        setDownloadUrl(response.data);
        setShowDownloadModal(true);
      } else {
        toast.info("No record found.");
      }
    } catch (error) {
      toast.error("An error occurred while downloading the report.");
    } finally {
      setShowLoading(false);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchTerm.length > 0) {
        fetchHostelReport(levelFilter, sessionFilter, searchTerm);
      }
      else if(searchTerm.length === 0){
        fetchHostelReport(levelFilter, sessionFilter,"",1)
      }
    }, 1000);

    return () => clearTimeout(handler);
  }, [searchTerm, levelFilter, sessionFilter, fetchHostelReport]);

  return (
    <div>
      {loading && <LoadingItem />}

      {downloadUrl && (
        <DownloadModal
          showDownloadModal={showDownloadModal}
          setShowDownloadModal={setShowDownloadModal}
          downloadUrl={downloadUrl}
          fileType={fileType}
          session={sessionFilter}
        />
      )}

      <Modal
        show={showFilter}
        onHide={() => setShowFilter(false)}
        className="course_subject_wrap"
        contentClassName="course-subject-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Hostel Report</Modal.Title>
        </Modal.Header>
        <Modal.Body className="student-modal-form">
          <Container>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleFormSubmit}
            >
              {(props: FormikProps<FormValues>) => {
                const {
                  values,
                  touched,
                  errors,
                  setFieldValue,
                  setFieldTouched,
                } = props;
                return (
                  <Form>
                    <Row>
                      <Col xs={12} md={6}>
                        <DropDown
                          label="Session"
                          name="session"
                          touched={touched}
                          errors={errors}
                          options={sessionOptions}
                          field="session"
                          value={{
                            value: values.session,
                            label: values.session,
                          }}
                          defaultValue={{
                            value: values.session,
                            label: values.session,
                          }}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          width="97%"
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <DropDown
                          label="Level"
                          touched={touched}
                          errors={errors}
                          value={{ value: values.level, label: values.level }}
                          options={levelOptions}
                          defaultValue={{ value: "All", label: "All Levels" }}
                          field="level"
                          setFieldTouched={setFieldTouched}
                          setFieldValue={setFieldValue}
                          width="97%"
                        />
                      </Col>
                    </Row>
                    <div className="modal-footer">
                      <Button
                        className="submit-btn"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? (
                          <DotLoader
                            color="white"
                            loading={loading}
                            size={30}
                            aria-label="Fetching..."
                          />
                        ) : (
                          "Fetch report"
                        )}
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Container>
        </Modal.Body>
      </Modal>

      <div className="report-table">
        {!loading && hostelData.length > 0 && (
          <div>
            <ReportTable
              show={show}
              items={hostelData}
              fetchReportDownload={fetchReportDownload}
              loading={loading}
              setFileType={setFileType}
              fileType={fileType}
              setSearchTerm={setSearchTerm}
              searchTerm={searchTerm}
              session={sessionFilter}
              level={levelFilter}
              currentPage={currentPage}
            />

            <div className="result-count">
              <p>
                Showing {lastPageCount} of {totalCount} results
              </p>
            </div>

            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              className="pagination_items"
              pageClassName="page_num"
              pageLinkClassName="page_link"
              activeClassName="active_page_link"
              previousClassName="previous_page_link"
              nextClassName="next_page_link"
              forcePage={currentPage - 1}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportModal;
