import React, { useEffect } from "react";
import { useState, useMemo } from "react";
import { InAppTemplate } from "../../shared/templates/portal";
import Modal from "react-bootstrap/Modal";
import * as Yup from "yup";
import Button from "react-bootstrap/Button";
import { Form, Formik, FormikProps } from "formik";
import NaijaStates from "naija-state-local-government";
import InputField from "../../shared/components/InputField";
import Col from "react-bootstrap/Col";
import DropDown from "../../shared/components/dropdown";
import Row from "react-bootstrap/Row";
import "./index.scss";
import { Container } from "react-bootstrap";

import {
  createNewStudent,
  loadAllProgrammes,
  loadFacultyAndDept,
} from "../../redux/action/admission";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { DotLoader } from "react-spinners";
import { history } from "../../shared/_helpers/history";
import { AlertMsg } from "../../shared/components/alert-msg";

const CreateStudentModal = ({ show, setShow }: any) => {
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedFaculty, setSelectedFaculty] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState("");

  const { faculties, allProgrammes } = useSelector(
    (state: any) => state.admissionReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadFacultyAndDept());
    dispatch(loadAllProgrammes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const statesInNigeria = useMemo(
    () =>
      NaijaStates.states().map((state: string) => {
        return { value: state, label: state };
      }),
    []
  );

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];

    for (let year = 2015; year <= currentYear; year++) {
      years.push({ value: year.toString(), label: year.toString() });
    }
    return years;
  };

  const handleCreateStudent = async (payload: any) => {
    setError("");
    setLoading(true);
    await createNewStudent(payload)
      .then(() => {
        toast.dismiss();
        toast.success("Student created successfully");
        setLoading(false);
        setShow(false);
        history.push("/app/portal-settings");
      })
      .catch((err) => {
        setLoading(false);
        toast.dismiss();
        if (
          err?.response?.data ===
          "Student with the matric number already exists"
        ) {
          setError(err?.response?.data);
        } else {
          setError("An error occured");
        }
      });
  };

  const formatFaculties = useMemo(
    () =>
      faculties.map((faculty: any) => {
        return {
          value: faculty.undergraduateFacultyName,
          label: faculty.undergraduateFacultyName,
        };
      }),
    [faculties]
  );

  const programmesList =
    allProgrammes
      .filter(
        (programme: any) => programme.departmentUniqueId === selectedDepartment
      )
      .map((prog: any) => {
        return {
          value: prog.programmeName,
          label: prog.programmeName,
        };
      }) || [];
  const departmentsOptions =
    faculties
      ?.find(
        (faculty: any) => faculty?.undergraduateFacultyName === selectedFaculty
      )
      ?.undergraduateDepartments?.map((dept: any) => {
        return {
          value: dept?.undergraduateDepartmentName,
          label: dept?.undergraduateDepartmentName,
          id: dept?.undergraduateDepartmentUniqueId,
        };
      }) || [];

  const initialValues = {
    matricNumber: "",
    surName: "",
    firstName: "",
    otherNames: "",
    registrationNumber: "",
    gender: "",
    maritalStatus: "",
    religion: "",
    nationality: "",
    state: "",
    lga: "",
    entryMode: "",
    entryYear: "",
    programme: "",
    phoneNumber: "",
    sessionAdmittedFrom: "",
    sessionAdmittedTo: "",
    level: "",
    department: "",
    faculty: "",
    isReturningStudent: "",
  };

  let checkValidationSchema = Yup.object().shape({
    registrationNumber: Yup.string().required("Required"),
    matricNumber: Yup.string().required("Required"),
    surName: Yup.string().required("Required"),
    firstName: Yup.string().required("Required"),
    gender: Yup.string().required("Required"),
    maritalStatus: Yup.string().required("Required"),
    religion: Yup.string().required("Required"),
    nationality: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    lga: Yup.string().required("Required"),
    entryMode: Yup.string().required("Required"),
    entryYear: Yup.string().required("Required"),
    programme: Yup.string().required("Required"),
    isReturningStudent: Yup.string().required("Required"),
    phoneNumber: Yup.string()
      .matches(/^\+?[0-9]{8,20}$/, "Phone number must be 8 digits or more")
      .required("Phone number is required"),
    sessionAdmittedFrom: Yup.string().required("Required"),
    sessionAdmittedTo: Yup.string().required("Required"),
    level: Yup.string().required("Required"),
    department: Yup.string().required("Required"),
    faculty: Yup.string().required("Required"),
  });

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
        setError("");
        history.push("/app/portal-settings");
      }}
      size="lg"
      contentClassName="student-details-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2 className="modal-h2">Create New Student</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Formik
            initialValues={initialValues}
            validationSchema={checkValidationSchema}
            onSubmit={(values) => {
              const {
                sessionAdmittedFrom,
                sessionAdmittedTo,
                department,
                faculty,
                isReturningStudent,
                ...rest
              } = values;
              
              let studentType = isReturningStudent=="NEW STUDENT"? false :true

              const studentInfo = {
                ...rest,
                isReturningStudent:studentType,
                sessionAdmitted: `${sessionAdmittedFrom}/${sessionAdmittedTo}`,
              };
              // console.log("studentInfo", studentInfo)
              handleCreateStudent(studentInfo);
            }}
          >
            {(props: FormikProps<any>) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                setFieldValue,
                setFieldTouched,
              } = props;
              return (
                <Form>
                  <Row>
                    <Col xs={12} md={4}>
                      <InputField
                        field="registrationNumber"
                        label="Reg. Number"
                        value={values.registrationNumber}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <InputField
                        field="matricNumber"
                        label="Matric No"
                        value={values.matricNumber}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <DropDown
                        width="100%"
                        label="Student Type"
                        touched={touched}
                        errors={errors}
                        options={[
                          { value: "NEW STUDENT", label: "NEW STUDENT" },
                          { value: "RETURNING STUDENT", label: "RETURNING STUDENT" },
                        ]}
                        value={{
                          value: values.isReturningStudent,
                          label: values.isReturningStudent,
                        }}
                        field="isReturningStudent"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={4}>
                      <InputField
                        field="firstName"
                        label="First Name"
                        value={values.firstName}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <InputField
                        field="surName"
                        label="Surname"
                        value={values.surName}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <InputField
                        mandatory={false}
                        field="otherNames"
                        label="Other Names"
                        value={values.otherNames}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={4}>
                      <DropDown
                        width="100%"
                        label="Mode of entry"
                        touched={touched}
                        errors={errors}
                        value={{
                          value: values.entryMode,
                          label: values.entryMode,
                        }}
                        options={[
                          { value: "UTME", label: "UTME" },
                          { value: "DE", label: "DE" },
                        ]}
                        field="entryMode"
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                      />
                    </Col>

                    <Col xs={12} md={4}>
                      <DropDown
                        width="100%"
                        label="Year Of Entry"
                        touched={touched}
                        errors={errors}
                        value={{
                          value: values.entryYear,
                          label: values.entryYear,
                        }}
                        options={generateYearOptions()}
                        field="entryYear"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                    </Col>

                    <Col xs={12} md={4}>
                      <DropDown
                        width="100%"
                        label="Level"
                        touched={touched}
                        errors={errors}
                        value={{
                          value: values.level,
                          label: values.level,
                        }}
                        options={[
                          { value: "100", label: "100" },
                          { value: "200", label: "200" },
                          { value: "300", label: "300" },
                          { value: "400", label: "400" },
                          { value: "500", label: "500" },
                          { value: "600", label: "600" },
                        ]}
                        field="level"
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={4}>
                      <DropDown
                        width="100%"
                        label="Session Admitted (from)"
                        touched={touched}
                        errors={errors}
                        value={{
                          value: values.sessionAdmittedFrom,
                          label: values.sessionAdmittedFrom,
                        }}
                        options={generateYearOptions()}
                        field="sessionAdmittedFrom"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                    </Col>

                    <Col xs={12} md={4}>
                      <DropDown
                        width="100%"
                        label="Session Admitted (to)"
                        touched={touched}
                        errors={errors}
                        value={{
                          value: values.sessionAdmittedTo,
                          label: values.sessionAdmittedTo,
                        }}
                        options={generateYearOptions()}
                        field="sessionAdmittedTo"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                    </Col>

                    <Col xs={12} md={4}>
                      <InputField
                        field="phoneNumber"
                        label="Phone Number"
                        value={values.phoneNumber}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={4}>
                      <DropDown
                        width="100%"
                        label="Faculty"
                        touched={touched}
                        errors={errors}
                        handleChange={setSelectedFaculty}
                        value={{
                          value: selectedFaculty,
                          label: selectedFaculty,
                        }}
                        options={formatFaculties}
                        field="faculty"
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <DropDown
                        width="100%"
                        label="Department"
                        touched={touched}
                        errors={errors}
                        handleChange={setSelectedDepartment}
                        value={{
                          value: values.department,
                          label: values.department,
                        }}
                        options={departmentsOptions}
                        field="department"
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        disabled={!selectedFaculty}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <DropDown
                        width="100%"
                        label="Course"
                        touched={touched}
                        errors={errors}
                        value={{
                          value: values.programme,
                          label: values.programme,
                        }}
                        options={programmesList}
                        field="programme"
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={4}>
                      <DropDown
                        width="100%"
                        label="Nationality"
                        touched={touched}
                        errors={errors}
                        options={[
                          { value: "NIGERIAN", label: "NIGERIA" },
                          { value: "NON-NIGERIA", label: "NON-NIGERIA" },
                        ]}
                        value={{
                          value: values.nationality,
                          label: values.nationality,
                        }}
                        field="nationality"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <DropDown
                        width="100%"
                        label="State"
                        name="state"
                        touched={touched}
                        errors={errors}
                        options={statesInNigeria}
                        field="state"
                        handleChange={handleChange}
                        value={{ value: values.state, label: values.state }}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                    </Col>

                    <Col xs={12} md={4}>
                      <DropDown
                        width="100%"
                        label="L.G.A"
                        name="lga"
                        touched={touched}
                        errors={errors}
                        options={NaijaStates.lgas(
                          values?.state || "Abia"
                        )?.lgas?.map((state: string) => {
                          return { value: state, label: state };
                        })}
                        field="lga"
                        value={{ value: values.lga, label: values.lga }}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={4}>
                      <DropDown
                        width="100%"
                        label="Gender"
                        touched={touched}
                        errors={errors}
                        options={[
                          { value: "male", label: "Male" },
                          { value: "female", label: "Female" },
                        ]}
                        value={{
                          value: values.gender,
                          label: values.gender,
                        }}
                        field="gender"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <DropDown
                        width="100%"
                        label="Marital Status"
                        touched={touched}
                        errors={errors}
                        options={[
                          { value: "single", label: "Single" },
                          { value: "Married", label: "Married" },
                        ]}
                        value={{
                          value: values.maritalStatus,
                          label: values.maritalStatus,
                        }}
                        field="maritalStatus"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                    </Col>

                    <Col xs={12} md={4}>
                      <DropDown
                        width="100%"
                        label="Religion"
                        touched={touched}
                        errors={errors}
                        options={[
                          { value: "Islam", label: "Islam" },
                          { value: "Christianity", label: "Christianity" },
                        ]}
                        value={{
                          value: values.religion,
                          label: values.religion,
                        }}
                        field="religion"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                    </Col>
                  </Row>

                  <div className="modal-footer">
                    <Button
                      className="submit-btn"
                      onClick={() => {
                        setShow(false);
                        history.push("/app/portal-settings");
                      }}
                      disabled={loading}
                    >
                      Close
                    </Button>
                    {error?.length ? (
                      <AlertMsg type="error" message={error} />
                    ) : null}
                    <Button
                      className="submit-btn"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? (
                        <DotLoader
                          color="white"
                          loading={loading}
                          size={30}
                          aria-label="Submitting"
                        />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

const CreateStudentWrap = () => {
  const [show, setShow] = useState<boolean>(true);
  return (
    <div className="modal-container">
      <CreateStudentModal show={show} setShow={setShow} />
    </div>
  );
};

const StudentManagementWrap = () => {
  return (
    <InAppTemplate
      pageTitle={`STUDENT MANAGEMENT`}
      childComponent={<CreateStudentWrap />}
    />
  );
};

export default StudentManagementWrap;
