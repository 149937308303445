import React from "react";
import { Dropdown, Button, ButtonGroup } from "react-bootstrap";

interface DownloadDropdownProps {
  loading: boolean;
  fileType: string;
  onSubmit: () => void;
  setFileType: (type: string) => void;
}

const DownloadDropdown: React.FC<DownloadDropdownProps> = ({
  loading,
  fileType,
  onSubmit,
  setFileType,
}) => {
  return (
    <Dropdown as={ButtonGroup}>
      {/* Download Button */}
      <Button
        disabled={loading}
        onClick={onSubmit}
        variant="success"
        className="download_cta"
      >
        {loading
          ? `Downloading as ${fileType}...`
          : `Download as ${fileType}`}
      </Button>

      {/* Dropdown Toggle */}
      
      <Dropdown.Toggle
        disabled={loading}
        split
        variant="success"
        id="dropdown-split-basic"
      />

      {/* Dropdown Menu */}
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => setFileType("CSV")}>
          CSV
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setFileType("EXCEL")}>
          Excel
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setFileType("PDF")}>
          PDF
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DownloadDropdown;