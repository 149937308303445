import generatePDF from "react-to-pdf";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import "./index.scss";
import PdfImage from "../../../../assets/Export-Pdf.png";
import PdfFile from "../../../../assets/pdffile.png";
import Excel from "../../../../assets/xlsx_icon.png";
import Csv from "../../../../assets/csvfile.jpg";
import { useState } from "react";
import { DotLoader } from "react-spinners";

interface DownloadModalProps {
  showDownloadModal: boolean;
  setShowDownloadModal: (value: boolean) => void;
  session: string;
  fileType: string;
  downloadUrl: string;
}
const DownloadModal = ({
  showDownloadModal,
  setShowDownloadModal,
  session,
  fileType,
  downloadUrl,
}: DownloadModalProps) => {
  const [downloading, setLoading] = useState(false);
  const options: any = {
    filename: `Hostel-Report.pd_${session}.pdf`,
    page: {
      orientation: "landscape",
    },
  };

  const getTargetElement = (): HTMLElement | null =>
    document.getElementById("table-to-export");
  

  const downloadPdf = async () => {
    setLoading(true);
    await generatePDF(getTargetElement, options);
    setLoading(false);
  };
  return (
    <Modal
      show={showDownloadModal}
      contentClassName="hostel-reports-modal"
      onHide={() => setShowDownloadModal(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="report-main-body">
        <Container>
          <img
            src={fileType === "PDF" ? PdfImage : fileType === "EXCEL" ? Excel : Csv}
            alt="pdf"
            className="report-pdf"
          />
          <div className="downloaded-file">
            <img
              src={fileType === "PDF" ? PdfFile : fileType === "EXCEL" ? Excel : Csv}
              alt="Downloaded file"
            />
            {fileType === "PDF"
              ? `Hostel-Report_${session}.pdf`
             : fileType === "EXCEL" ? `Hostel-Report_${session}.Excel`
             : `Hostel-Report_${session}.csv`}
          </div>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        {fileType === "pdf" ? (
          <button onClick={downloadPdf} className="download-btn">
            {downloading ? "Downloading..." : "Download"}{" "}
            {downloading ? (
              <DotLoader
                color="white"
                loading={downloading}
                size={30}
                aria-label="Submitting"
              />
            ) : null}
          </button>
        ) : (
          <a
            href={downloadUrl}
            target="_blank"
            rel="noreferrer"
            className="download-btn"
          >
            Download
          </a>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default DownloadModal;
