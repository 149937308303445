import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AlertMsg } from "../../shared/components/alert-msg";
import { adminInAppActions } from "../../redux/action/portal";
import { appConstantsPortal } from "../../redux/action-constants/portal";
import { InAppTemplate } from "../../shared/templates/portal";
import "./index.scss";
import Navigation from "../../shared/components/navigation/Navigation";

const adminGetAStudentInvoiceRequestAction = async ({
  pageProps,
  payload,
  invoiceType,
}: any) => {
  await pageProps.adminGetAStudentInvoiceAction(payload, invoiceType);
};
const adminUpdateAStudentInvoiceActionRequest = async ({
  pageProps,
  payload,
  invoiceType,
  actionType,
}: any) => {
  await pageProps.adminUpdateAStudentInvoiceAction(
    payload,
    invoiceType,
    actionType
  );
};


const UpdateStatus = ({ pageProps, }: any) => {


  let fetchRequestInfo = pageProps.adminGetAStudentInvoiceRequest;
  fetchRequestInfo = fetchRequestInfo?.request_data?.response?.data;
  let requestInfo = pageProps.adminUpdateAStudentInvoiceRequest;

  return (
    <>
      <div className="payments_info_wrap">
      
        <div className="payment_details">
          {requestInfo.request_status !==
            appConstantsPortal?.UPDATE_STUDENT_INVOICE_SUCCESS && (
            <div className="txtn_info_wrap">
              <div className="each_info_detail">
                <div className="info_title">INVOICE NUMBER :</div>
                <div className="info_data">
                  {fetchRequestInfo?.invoiceNumber}
                </div>
              </div>
              <div className="each_info_detail">
                <div className="info_title">INVOICE DATE :</div>
                <div className="info_data">{fetchRequestInfo?.dateCreated}</div>
              </div>
              <div className="each_info_detail">
                <div className="info_title">INVOICE SESSION :</div>
                <div className="info_data">{fetchRequestInfo?.session}</div>
              </div>
              <div className="each_info_detail">
                <div className="info_title">MATRIC NO :</div>
                <div className="info_data">
                  {fetchRequestInfo?.matricNumber}
                </div>
              </div>
              <div className="each_info_detail">
                <div className="info_title">LEVEL :</div>
                <div className="info_data">{fetchRequestInfo?.level}</div>
              </div>
              <div className="each_info_detail">
                <div className="info_title">STUDENT NAME :</div>
                <div className="info_data">{fetchRequestInfo?.studentName}</div>
              </div>
              <div className="each_info_detail">
                <div className="info_title">SERVICE :</div>
                <div className="info_data">{fetchRequestInfo?.paymentType}</div>
              </div>
              <div className="each_info_detail">
                <div className="info_title">TOTAL AMOUNT PAYABLE :</div>
                <div className="info_data">{fetchRequestInfo?.amount}</div>
              </div>
              <div className="each_info_detail">
                <div className="info_title">PAYMENT STATUS :</div>
                <div className="info_data">
                  {fetchRequestInfo?.paymentStatus}
                </div>
              </div>
       
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const SettingSearchForm = ({ pageProps, setInvoiceSource }: any) => {
  let requestInfo = pageProps.adminGetAStudentInvoiceRequest;
  let updateStatusRequestInfo = pageProps.adminUpdateAStudentInvoiceRequest;

  let checkValidationSchema = Yup.object().shape({
    invoiceNumber: Yup.string().required("Required"),
    invoiceType: Yup.string().required("Required"),
  });
  let initialValues: any = { invoiceNumber: "" };

  return (
    <div className="student_form">
      <Formik
        initialValues={initialValues}
        validationSchema={checkValidationSchema}
        onSubmit={async (values) => {
          let { invoiceNumber, invoiceType } = values;
          let payload: any = `?invoiceNumber=${invoiceNumber}`;
          adminUpdateAStudentInvoiceActionRequest({
            pageProps,
            payload: "CLEAR",
          });
          setInvoiceSource(invoiceType);
          await adminGetAStudentInvoiceRequestAction({
            pageProps,
            payload,
            invoiceType,
          });
        }}
      >
        {(props: FormikProps<any>) => {
          const {
            values,
            touched,
            errors,
            handleChange,
          } = props;
          return (
            <Form>
              <div className="search_with_filter">
                <div className="form-group">
                  <label className="label">Invoice Type</label>
                  <div
                    className={
                      errors.invoiceType && touched.invoiceType
                        ? "invalid-input-wrap"
                        : ""
                    }
                  >
                    <select
                      name="invoiceType"
                      id="invoiceType"
                      value={values?.invoiceType}
                      onChange={handleChange}
                      className={`input-wrap ${
                        errors.invoiceType && touched.invoiceType
                          ? "is-invalid-input "
                          : !errors.invoiceType && values.invoiceType !== ""
                          ? "valid-input"
                          : ""
                      }`}
                    >
                      <option value="">Select </option>
                      <option value="paystack">PayStack </option>
                      <option value="cbs">CBS </option>
                    </select>
                  </div>
                  <ErrorMessage
                    name="invoiceType"
                    className="form-input-error"
                    component="div"
                  />
                </div>

                <div className="form-group">
                  <label className="label">Invoice number </label>
                  <div
                    className={
                      errors.invoiceNumber && touched.invoiceNumber
                        ? "invalid-input-wrap"
                        : ""
                    }
                  >
                    <Field
                      value={values.invoiceNumber}
                      id="invoiceNumber"
                      name="invoiceNumber"
                      type="text"
                      disabled={updateStatusRequestInfo?.is_request_processing}
                      onChange={handleChange("invoiceNumber")}
                      placeholder="Enter invoice number"
                      className={`input-wrap ${
                        errors.invoiceNumber && touched.invoiceNumber
                          ? "is-invalid-input "
                          : !errors.invoiceNumber && values.invoiceNumber !== ""
                          ? "valid-input"
                          : ""
                      }`}
                    />
                  </div>
                  <ErrorMessage
                    name="invoiceNumber"
                    className="form-input-error"
                    component="div"
                  />
                </div>
              </div>

              {requestInfo.request_status ===
                appConstantsPortal?.GET_STUDENT_INVOICE_FAILURE && (
                <AlertMsg
                  type="error"
                  message={requestInfo?.request_data.error}
                />
              )}
              <div className="submit_cta">
                <button
                  disabled={
                    requestInfo?.is_request_processing ||
                    updateStatusRequestInfo?.is_request_processing
                  }
                  className="btn shaded"
                  type="submit"
                >
                  {requestInfo?.is_request_processing
                    ? "Please wait..."
                    : "Search Invoice"}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const SettingsWrap = ({ pageProps }: any) => {
  const [invoiceSource, setInvoiceSource] = useState<string>("");
  let requestInfo = pageProps.adminGetAStudentInvoiceRequest;

  useEffect(() => {
    adminGetAStudentInvoiceRequestAction({
      pageProps,
      payload: "CLEAR",
    });
    adminUpdateAStudentInvoiceActionRequest({
      pageProps,
      payload: "CLEAR",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="student_password settings_wrap_content">
       <div className="back-to-portal">
      <Navigation to="/app/bursary-reports"  text = "Back to Bursary Report"/>
      </div>
      <SettingSearchForm
        invoiceSource={invoiceSource}
        setInvoiceSource={setInvoiceSource}
        pageProps={pageProps}
      />

      {requestInfo.request_status ===
        appConstantsPortal?.GET_STUDENT_INVOICE_SUCCESS && (
        <UpdateStatus invoiceSource={invoiceSource} pageProps={pageProps} />
      )}
    </div>
  );
};

const SearchPaymentInvoiceWrap = (pageProps: any) => {
  return (
    <InAppTemplate
      pageTitle={`SEARCH INVOICES`}
      childComponent={<SettingsWrap pageProps={pageProps} />}
    />
  );
};

const mapDispatchToProps = {
  adminGetAStudentInvoiceAction: adminInAppActions.AdminGetAStudentInvoice,
  adminUpdateAStudentInvoiceAction:
    adminInAppActions.AdminUpdateAStudentInvoice,
};
const mapStateToProps = (state: any) => ({
  adminGetAStudentInvoiceRequest:
    state.allUGradPortalReducers.adminGetAStudentInvoiceReducer,
  adminUpdateAStudentInvoiceRequest:
    state.allUGradPortalReducers.adminUpdateAStudentInvoiceReducer,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchPaymentInvoiceWrap);

