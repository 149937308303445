import "react-loading-skeleton/dist/skeleton.css";
import "./index.scss";
import Navigation from "../../shared/components/navigation/Navigation";
import RoomModal from "./RoomModal";
import { useCallback, useEffect, useState } from "react";
import {
  getAllRooms,
  getAllRoomsSearch,
  getHostelSiteBlock,
} from "../../redux/action/hostels";
import AddStudentToRoomModal from "./room-view/AddStudent";
import ReactPaginate from "react-paginate";
import SearchBox from "../../shared/components/searchbox";
import RoomsTable from "./RoomsTable";

export interface Allocation {
  level: string;
  allocationPercentage: number;
}

export interface Room {
  allocation: Allocation[];
  hostelSite: string;
  blockName: string;
  gender: string;
  roomName: string;
  totalBedSpace: string;
  allocatedBedSpace: string;
  isReserved: boolean;
  roomUniqueId: "";
  amount: string;
  "100L": string;
  "200L": string;
  "300L": string;
  "400L": string;
  "500L": string;
}

const initialValues = {
  hostelSite: "",
  blockName: "",
  gender: "",
  roomName: "",
  totalBedSpace: "",
  allocatedBedSpace: "",
  isReserved: false,
  roomUniqueId: "",
  amount: "",
  "100L": "",
  "200L": "",
  "300L": "",
  "400L": "",
  "500L": "",
};

const InitialSavedPages = () => {
  const savedPage = localStorage.getItem("currentPage");
  return savedPage ? parseInt(savedPage, 10) : 1;
};
const RoomsContent = () => {
  const [show, setShow] = useState(false);
  const [roomInfo, setRoomInfo] = useState(initialValues);
  const [allRooms, setAllRooms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refetch, setRefetch] = useState(null);
  const [blocks, setBlocks] = useState([]);
  const [addStudentModal, setAddStudentModal] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState<any>("");
  const [currentPage, setCurrentPage] = useState<number>(InitialSavedPages());
  const [selectedRowId, setSelectedRowId] = useState<string | null>(() => {
    return localStorage.getItem("selectedRowId");
  });

  const pageCount = Math.ceil(totalCount / 15);
  const itemsPerPage = 15;
  const lastPageCount = (currentPage - 1) * itemsPerPage + allRooms.length;

  const handlePageClick = (event: any) => {
    const newPage = event.selected + 1;

    setCurrentPage(newPage);
    localStorage.setItem("currentPage", newPage.toString()); // Save the current page to localStorage

    fetchData(newPage);
    setSelectedRowId(null);
  };

  const fetchData = useCallback(
    async (page: number) => {
      setLoading(true);

      if (searchTerm) {
        const searchResponse = await getAllRoomsSearch(page, searchTerm);
        if (searchResponse?.data?.items?.length) {
          setAllRooms(searchResponse.data.items);
          setTotalCount(searchResponse?.data?.totalCount);
        }
      } else {
        const roomsResponse = await getAllRooms(page);
        if (roomsResponse?.data?.items?.length) {
          setAllRooms(roomsResponse.data.items);
          setTotalCount(roomsResponse?.data?.totalCount);
        }
      }
      setLoading(false);
    },
    [searchTerm, refetch]
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchData(currentPage);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm, fetchData]);

  const fetchSiteBlock = useCallback(async () => {
    const blocksResponse = await getHostelSiteBlock();
    if (blocksResponse?.data?.length) {
      setBlocks(blocksResponse.data);
    }
  }, []);

  useEffect(() => {
    fetchSiteBlock();
  }, []);

  return (
    <div className="room-management">
      <RoomModal
        show={show}
        setShow={setShow}
        setRoomInfo={setRoomInfo}
        roomInfo={roomInfo}
        blocks={blocks}
        setRefetch={setRefetch}
        initialValues={initialValues}
        refetch={refetch}
      />
      <AddStudentToRoomModal
        show={addStudentModal}
        setShow={setAddStudentModal}
        room={roomInfo}
      />
      <div className="room-management-top-buttons">
        <Navigation
          to="/app/hostel/dashboard"
          text="Back to Hostel Dashboard"
        />
      </div>
      <div className="inputWrap">
        <SearchBox
          placeholder="Search..."
          setSearchParameter={setSearchTerm}
          searchParameter={searchTerm}
        />

        <button
          className="add-btn"
          onClick={() => {
            setRoomInfo(initialValues);
            setShow(true);
          }}
        >
          Add Room
        </button>
      </div>
      <RoomsTable
        allRooms={allRooms}
        loading={loading}
        setRoomInfo={setRoomInfo}
        currentPage={currentPage}
        setShow={setShow}
        setAddStudentModal={setAddStudentModal}
        setSelectedRowId={setSelectedRowId}
        selectedRowId={selectedRowId}
      />
      {allRooms?.length > 0 ? (
        <div className="result-count">
          <p>
            Showing {lastPageCount} of {totalCount} results
          </p>
        </div>
      ) : null}

      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        className="pagination_items"
        pageClassName="page_num"
        pageLinkClassName="page_link"
        activeClassName="active_page_link"
        previousClassName="previous_page_link"
        nextClassName="next_page_link"
        forcePage={currentPage - 1}
      />
    </div>
  );
};

export default RoomsContent;
