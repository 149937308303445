import React from 'react'
import Navigation from '../../../shared/components/navigation/Navigation'
import { Link } from 'react-router-dom'

const SettingsDashboard = () => {
  return (
    <div className="putme-header">
    <div className="back">
      <Navigation to="/app/hostel/dashboard" text="Back to Hostel Dasbhoard" />
    </div>

    <div className="settings_list">
      <div className="each_setting">
        <div className="setting_name">Block Management</div>
        <div className="setting_link">
          <Link className="btn" to="/app/hostel/configuration">
            Update
          </Link>{" "}
        </div>
      </div>
      <div className="each_setting">
        <div className="setting_name">Allocation Management</div>
        <div className="setting_link">
          <Link className="btn" to="/app/hostel/allocation-management">
          Update
          </Link>{" "}
        </div>
      </div>
      <div className="each_setting">
        <div className="setting_name">Blacklist Hostel Student</div>
        <div className="setting_link">
          <Link className="btn" to="/app/hostel/blacklisted-students">
          Update
          </Link>{" "}
        </div>
      </div>
      <div className="each_setting">
        <div className="setting_name">Accommodation Status</div>
        <div className="setting_link">
          <Link className="btn" to="/app/hostel/accommodation-status">
          Update
          </Link>{" "}
        </div>
      </div>
    </div>
  </div>
  )
}

export default SettingsDashboard;
