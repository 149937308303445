import React, { useState, useEffect } from "react";
import { Form, Formik, FormikProps } from "formik";
import Button from "react-bootstrap/Button";
import { DotLoader } from "react-spinners";
import Navigation from "../../../shared/components/navigation/Navigation";
import DropDown from "../../../shared/components/dropdown";
import { toast } from "react-toastify";
import {
  addOrUpdatePercentage,
  getHostelPercentage,
} from "../../../redux/action/hostels";
import "./index.scss";

type Levels = "100L" | "200L" | "300L" | "400L" | "500L/SPILL-OVER";

type ReservationSettingModel = {
  level: number;
  percentage: any;
};

type Payload = {
  reservationSettingModel: ReservationSettingModel[];
};

const AllocationManagement = () => {
  const initialValues: Record<Levels | "percentage", string> = {
    percentage: "",
    "100L": "0",
    "200L": "0",
    "300L": "0",
    "400L": "0",
    "500L/SPILL-OVER": "0",
  };

  const [loading, setLoading] = useState(false);
  const [allocationError, setAllocationError] = useState(false);
  const [fetchedValues, setFetchedValues] =
    useState<Record<Levels | "percentage", string>>(initialValues);

  const reserveNumbers = [0,  5, 10, 15, 20, 25, 30, 35, 40, 45,50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100]
  const levels: Levels[] = ["100L", "200L", "300L", "400L", "500L/SPILL-OVER"];
  const reserveObj = reserveNumbers.map((num) => ({
    label: `${num}%`,
    value: num,
  }));

  useEffect(() => {
    const fetchHostelPercentage = async () => {
      try {
        const response = await getHostelPercentage();
        if (response.status === 200) {
          const fetchedData = response.data;

          const newValues = levels.reduce(
            (acc, level) => {
              const matchingLevel = fetchedData.find(
                (item: ReservationSettingModel) =>
                  item.level === parseInt(level)
              );
              if (matchingLevel) {
                acc[level] = String(matchingLevel.percentage);
              }
              return acc;
            },
            { ...initialValues }
          );

          setFetchedValues(newValues);
        }
      } catch (error) {
        toast.error("Failed to fetch, please try again");
      }
    };

    fetchHostelPercentage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="form-wrapper">
      <div className="back-to-report">
        <Navigation to="/app/hostel/settings" text="Back to Accommodation Settings" />
      </div>

      <Formik
        enableReinitialize
        initialValues={fetchedValues}
        onSubmit={async (values) => {
          setAllocationError(false);

          const reservationSettingModel = levels.map((level) => ({
            level: parseInt(level, 10),
            percentage: values[level],
          }));

          const totalAllocation = reservationSettingModel.reduce(
            (acc, el) => acc + Number(el.percentage),
            0
          );

          if (totalAllocation !== 100) {
            setLoading(false);
            return setAllocationError(true);
          }

          setLoading(true);

          const payload: Payload = { reservationSettingModel };
          const response = await addOrUpdatePercentage(
            payload.reservationSettingModel
          );

          setLoading(false);
          if (response?.status === 200) {
            toast.success("Percentage allocation configured successfully.");
          } else {
            toast.error("An error occurred, please try again later.");
          }
        }}
      >
        {(props: FormikProps<typeof initialValues>) => {
          const { values, touched, errors, setFieldValue, setFieldTouched } =
            props;

          return (
            <Form>
              <div className="allocations">
                {levels.map((level, index) => (
                  <DropDown
                    className="form-dropdown"
                    key={index}
                    label={`${level}`}
                    touched={touched}
                    errors={errors}
                    options={reserveObj}
                    field={level}
                    value={{
                      value: values[level],
                      label: values[level],
                    }}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  />
                ))}
              </div>

              {allocationError ? (
                <p
                  style={{
                    color: "red",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  Total allocation must sum up to 100%
                </p>
              ) : null}

              <div className="modal-footer">
                <Button className="submit-btn" type="submit" disabled={loading}>
                  {loading ? (
                    <DotLoader
                      color="white"
                      loading={loading}
                      size={30}
                      aria-label="Submitting"
                    />
                  ) : (
                    "Update"
                  )}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AllocationManagement;
