import { Form, Formik, FormikProps } from "formik";
import { Button, Col, Container, Row } from "react-bootstrap";
import InputField from "../../../shared/components/InputField";
import DropDown from "../../../shared/components/dropdown";
import { useMemo, useState } from "react";
import NaijaStates from "naija-state-local-government";
import {
  addStudentToAdmissionList,
  getMatricNumberFormation,
} from "../../../redux/action/admission";
import * as Yup from "yup";
import { handleRequestErrors } from "../../../shared/utils";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { DotLoader } from "react-spinners";
import Navigation from "../../../shared/components/navigation/Navigation";
import "./index.scss";

const TransferManagementModal = ({ show, setShow }: any) => {
  const initialValues = {
    registrationNumber: "",
    state: "",
    fullName: "",
    lga: "",
    faculty: "",
    nationality: "",
    totalScore: "DE",
    department: "",
    isNotApproved: false,
    notApprovedReason: "",
    sex: "",
    programme: "",
    levelOfEntry: "",
    session: "",
    matricNumberFormation: "",
  };

  const [studentInfo, setStudentInfo] = useState<any>(initialValues);
  const [selectedDepartment, setSelectedDepartment] = useState<string>("");
  const [selectedFaculty, setSelectedFaculty] = useState<string>("");
  const [matricNumberFormation, setMatricNumberFormation] =
    useState<string>("");

  let currentSession: any = localStorage.getItem("adXAuth");
  currentSession = JSON.parse(currentSession);
  const session = currentSession?.currentSession?.session;

  const dispatch = useDispatch();

  const sessionOptions = [
    {
      value: session,
      label: session,
    },
  ];

  const statesInNigeria = useMemo(
    () =>
      NaijaStates.states().map((state: string) => {
        return { value: state, label: state };
      }),
    []
  );

  
  const { faculties, allProgrammes, addNewStudentLoading } = useSelector(
    (state: any) => state.admissionReducer
  );

  const formatFaculties = useMemo(
    () =>
      faculties.map((faculty: any) => {
        return {
          value: faculty.undergraduateFacultyName,
          label: faculty.undergraduateFacultyName,
        };
      }),
    [faculties]
  );

  const departmentsOptions =
    faculties
      ?.find(
        (faculty: any) => faculty?.undergraduateFacultyName === selectedFaculty
      )
      ?.undergraduateDepartments?.map((dept: any) => {
        return {
          value: dept?.undergraduateDepartmentName,
          label: dept?.undergraduateDepartmentName,
          id: dept?.undergraduateDepartmentUniqueId,
        };
      }) || [];

  const programmesList =
    allProgrammes
      .filter(
        (programme: any) => programme.departmentUniqueId === selectedDepartment
      )
      .map((prog: any) => {
        return {
          value: prog.programmeName,
          label: prog.programmeName,
        };
      }) || [];
  const getMatricNumber = async (programme: string) => {
    await getMatricNumberFormation(programme)
      .then((res) => {
        setMatricNumberFormation(res.data);
      })
      .catch((err: any) => {
        handleRequestErrors(err);
      });
  };

   let checkValidationSchema = Yup.object().shape({
      registrationNumber: Yup.string().required("Required"),
      state: Yup.string().required("Required"),
      fullName: Yup.string().required("Required"),
      lga: Yup.string().required("Required"),
      faculty: Yup.string().required("Required"),
      nationality: Yup.string().required("Required"),
      department: Yup.string().required("Required"),
      sex: Yup.string().required("Required"),
      programme: Yup.string().required("Required"),
      levelOfEntry: Yup.string().required("Required"),
      session: Yup.string().required("Required"),
    });

  return (
    <>
      <Container>
        <div className="transfer-form">
          <div className="back-to-portal">
            <Navigation
              to="/app/portal-settings"
              text="Back to Portal Settings"
            />
          </div>
          <Formik
            initialValues={studentInfo}
            validationSchema={checkValidationSchema}
            onSubmit={async (values, { resetForm }) => {
              const valuesObj = {
                ...values,
                matricNumberFormation:
                  matricNumberFormation || values.matricNumberFormation,
              };
               await dispatch(
                addStudentToAdmissionList(
                  valuesObj,
                  setShow,
                  resetForm,
                  initialValues,
                  session
                )
              );
              
              setMatricNumberFormation("");
            }}
          >
            {(props: FormikProps<any>) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                setFieldValue,
                setFieldTouched,

              } = props;
              return (
                <Form>
                  <Row>
                    <Col xs={12} md={6}>
                      <InputField
                        field="registrationNumber"
                        label="Reg. Number"
                        value={values.registrationNumber}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                        className="input-field"
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <DropDown
                        label="Nationality"
                        touched={touched}
                        errors={errors}
                        options={[
                          { value: "NIGERIAN", label: "NIGERIA" },
                          { value: "NON-NIGERIA", label: "NON-NIGERIA" },
                        ]}
                        value={{
                          value: values.nationality,
                          label: values.nationality,
                        }}
                        defaultValue={{
                          value: values.nationality,
                          label: values.nationality,
                        }}
                        field="nationality"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={6}>
                      <InputField
                        field="fullName"
                        label="Full Name"
                        value={values.fullName}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <DropDown
                        label="State"
                        name="state"
                        touched={touched}
                        errors={errors}
                        options={statesInNigeria}
                        field="state"
                        handleChange={handleChange}
                        value={{ value: values.state, label: values.state }}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        defaultValue={{
                          value: values.state,
                          label: values.state,
                        }}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={6}>
                      <DropDown
                        label="Faculty"
                        touched={touched}
                        errors={errors}
                        options={formatFaculties}
                        field="faculty"
                        setFieldValue={setFieldValue}
                        handleChange={setSelectedFaculty}
                        setFieldTouched={setFieldTouched}
                        value={{
                          value: values.faculty,
                          label: values.faculty,
                        }}
                        defaultValue={{
                          value: values.faculty,
                          label: values.faculty,
                        }}
                      />
                    </Col>

                    <Col xs={12} md={6}>
                      <DropDown
                        label="L.G.A"
                        name="lga"
                        touched={touched}
                        errors={errors}
                        options={NaijaStates.lgas(
                          values?.state || "Abia"
                        )?.lgas?.map((state: string) => {
                          return { value: state, label: state };
                        })}
                        field="lga"
                        value={{ value: values.lga, label: values.lga }}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        defaultValue={{
                          value: values.lga,
                          label: values.lga,
                        }}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={6}>
                      <DropDown
                        label="Department"
                        touched={touched}
                        errors={errors}
                        options={departmentsOptions}
                        field="department"
                        disabled={!values.faculty}
                        value={{
                          value: values.department,
                          label: values.department,
                        }}
                        defaultValue={{
                          value: values.department,
                          label: values.department,
                        }}
                        setFieldValue={setFieldValue}
                        handleChange={setSelectedDepartment}
                        setFieldTouched={setFieldTouched}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <DropDown
                        label="Session"
                        name="session"
                        touched={touched}
                        errors={errors}
                        options={sessionOptions}
                        field="session"
                        value={{
                          value: values.session,
                          label: values.session,
                        }}
                        defaultValue={{
                          value: values.session,
                          label: values.session,
                        }}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={6}>
                      <DropDown
                        label="Course"
                        name="programme"
                        touched={touched}
                        errors={errors}
                        disabled={!values.department}
                        options={programmesList}
                        field="programme"
                        handleChange={getMatricNumber}
                        value={{
                          value: values.programme,
                          label: values.programme,
                        }}
                        defaultValue={{
                          value: values.programme,
                          label: values.programme,
                        }}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <InputField
                        field="matricNumberFormation"
                        label="Matric Number Formation"
                        value={
                          matricNumberFormation || values.matricNumberFormation
                        }
                        touched={touched}
                        errors={errors}
                        disabled
                        handleChange={handleChange}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={6}>
                      <DropDown
                        label="Level of Entry"
                        touched={touched}
                        errors={errors}
                        value={{
                          value: values.levelOfEntry,
                          label: values.levelOfEntry,
                        }}
                        defaultValue={{
                          value: values.levelOfEntry,
                          label: values.levelOfEntry,
                        }}
                        options={[
                          { value: "100", label: "100" },
                          { value: "200", label: "200" },
                        ]}
                        field="levelOfEntry"
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                      />
                    </Col>

                    <Col xs={12} md={6}>
                      <DropDown
                        label="Sex"
                        touched={touched}
                        errors={errors}
                        options={[
                          { value: "M", label: "Male" },
                          { value: "F", label: "Female" },
                        ]}
                        value={{
                          value: values.sex,
                          label:
                            values.sex === "M"
                              ? "MALE"
                              : values.sex === "F"
                              ? "Female"
                              : "",
                        }}
                        defaultValue={{
                          value: values.sex,
                          label: values.sex,
                        }}
                        field="sex"
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                      />
                    </Col>
                  </Row>

                  <div className="modal-footer">
                    <Button
                      className="cancel-btn"
                      onClick={() => {
                        setShow(false);
                        setStudentInfo(initialValues);
                        setMatricNumberFormation("");
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="submit-btn"
                      type="submit"
                      disabled={addNewStudentLoading}
                    >
                      {addNewStudentLoading ? (
                        <DotLoader
                          color="white"
                          loading={addNewStudentLoading}
                          size={30}
                          aria-label="Submitting"
                        />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Container>
    </>
  );
};

export default TransferManagementModal;
